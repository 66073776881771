import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  decrement,
  increment,
  incrementByAmount,
  incrementAsync,
  incrementIfOdd,
  selectCount,
} from './counterSlice';
import styles from './Counter.module.css';

export function Counter() {
  const count = useSelector(selectCount);
  const dispatch = useDispatch();
  const [incrementAmount, setIncrementAmount] = useState('2');

  const incrementValue = Number(incrementAmount) || 0;

  return (
    <div>
      <div
        className="kiss"
        aria-label="Click me!"
        onClick={() => dispatch(increment())}
      >
        😗
      </div>
      <div className={styles.row}>
        <span className={styles.value}
          aria-label="Set increment amount"
          >Kisses:  
          </span>
        <span className={styles.value}>{count}</span>
      </div>

    </div>
  );
}
